import { Fragment } from "react";
import styles from "./App.module.css";
import InConstruction from "./InConstruction";

function App() {
  const inConstruction = true;

  return (
    <Fragment>
      {!inConstruction ? <div className="App">APP</div> : <InConstruction />}
    </Fragment>
  );
}

export default App;
