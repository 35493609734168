import styles from "./InConstruction.module.css";

import logo from "./assets/more life logo.png";

const InConstruction = () => {
  return (
    <div className={styles.wrapper}>
      <img className={styles.logo} src={logo} />
    </div>
  );
};

export default InConstruction;
